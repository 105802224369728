import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const CustomLogoDesignService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="custom-logo-design-service service-sub-part">
            <header className="page-header">
                <h1>Custom Logo Design Services</h1>
                <p>We create unique and professional logos that represent your brand’s identity.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Custom Logo Design</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Crafting Memorable Brand Identities</h2>
                <p>
                    At DiziDesert, we specialize in designing custom logos that capture the essence of your brand. Whether you're launching a new business or rebranding, we craft logos that are not only visually appealing but also meaningful and memorable.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiBrushAiFillIcon}</div>
                    <h3>Creative & Unique Designs</h3>
                    <p>
                        Our team of designers creates logos that are distinct, creative, and perfectly reflect your brand’s personality and values.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaSketchIcon}</div>
                    <h3>Tailored to Your Brand</h3>
                    <p>
                        We take time to understand your business and design a logo that aligns with your goals, vision, and target audience.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiCameraLensAiFillIcon}</div>
                    <h3>Color Theory Expertise</h3>
                    <p>
                        Our designers use color psychology and theory to create logos that evoke the right emotions and connect with your audience.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdHighQualityIcon}</div>
                    <h3>High-Quality Designs</h3>
                    <p>
                        We deliver logos in vector formats that are scalable for any size or medium, from business cards to billboards.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbFileTypeZipIcon}</div>
                    <h3>File Formats for Every Use</h3>
                    <p>
                        Get your logo in multiple formats (JPG, PNG, SVG, EPS, etc.), ensuring you can use it across all platforms and mediums.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaCogsIcon}</div>
                    <h3>Versatile Logo Designs</h3>
                    <p>
                        Whether you need a modern, minimalist design or something more intricate, we adapt our approach to suit your style and business needs.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Custom Logo Design?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in creating logos that stand out.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Custom solutions that reflect your brand’s identity.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Timely delivery with no compromise on quality.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Logo designs that work across digital and print platforms.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Create Your Brand Identity?</h2>
                    <p>
                        Let’s collaborate to create a logo that captures the essence of your brand. Contact us today and start building your visual identity.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default CustomLogoDesignService;
