
export const dataSetForBasicData = {    
    BrandNameOrCompanyName: document.title,
    MainHositingName:"dizidesert.com",
    CallingNumber: "7015171484",
    WhatsAppNumber: "7015171484",
    EmailAddress: "support@dizidesert.com",
    DirectionLinkRegisterOffice: "https://maps.app.goo.gl/a2kuQvyX6kGo2Fgv5",
    DirectionLinkCorporateOffice: "https://maps.app.goo.gl/a2kuQvyX6kGo2Fgv5",
    WebSiteLink: "https://dizidesert.com",
    BrandRegisterAddress: "Silarpur, Ateli, Mahendergarh, Haryana, India 123021",
    BrandCorporateAddress: "Gurugram, Haryana, India 123021",
    FollowSocialMediaButton: "https://www.instagram.com/dizidesert",
    FollowLinkFacebook: "https://www.facebook.com/dizidesert.pvt.ltd",
    FollowLinkInstagram: "https://www.instagram.com/dizidesert",
    FollowLinkTwitter: "https://twitter.com/",
    FollowLinkYouTube: "https://youtube.com/@dizidesert",
    FollowLinkLinkedin: "https://in.linkedin.com/company/dizidesert",
};