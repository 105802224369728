import React from "react";
import "./ComingSoon.css";
import { ImportIcon } from "../../pages/ImportIcon";
import { NavLink } from "react-router-dom"; // Import NavLink

const ComingSoon = ({pageName}) => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon-content">
                <h1 className="coming-soon-title">{ pageName } Coming Soon</h1>
                <p className="coming-soon-text">
                    We’re working hard to bring you an amazing experience. Stay tuned!
                </p>
                <div className="loader">
                    <span>{ImportIcon.TbClockEditIcon}</span>
                    <span>{ImportIcon.TbClockCodeIcon}</span>
                    <span>{ImportIcon.TbClockCogIcon}</span>
                    <span>{ImportIcon.TbClockDownIcon}</span>
                    <span>{ImportIcon.TbClockCheckIcon}</span>
                </div>
                <NavLink to="/" className="home-btn">
                    Go to Home
                </NavLink>
            </div>
        </div>
    );
};

export default ComingSoon;
