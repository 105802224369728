import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const UIUXDesignService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ui-ux-design-service service-sub-part">
            <header className="page-header">
                <h1>UI/UX Design Services</h1>
                <p>Crafting intuitive and engaging designs for exceptional user experiences.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">UI/UX Design</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Designing User-Centered Interfaces</h2>
                <p>
                    At DiziDesert, our UI/UX design services focus on creating visually stunning and user-friendly interfaces. We combine creativity and usability to design experiences that resonate with your target audience.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaPencilRulerIcon}</div>
                    <h3>Custom UI Design</h3>
                    <p>
                        We create unique, visually appealing designs tailored to your brand identity and user preferences.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.AiFillExperimentIcon}</div>
                    <h3>User Experience Optimization</h3>
                    <p>
                        Enhance user satisfaction with seamless navigation, intuitive layouts, and interactive elements.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.PiDevicesBoldIcon}</div>
                    <h3>Responsive Design</h3>
                    <p>
                        Ensure your applications and websites look and function flawlessly across all devices.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.GiWoodFrameIcon}</div>
                    <h3>Prototyping and Wireframing</h3>
                    <p>
                        Visualize your ideas with detailed prototypes and wireframes before the development phase.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.VscFeedbackIcon}</div>
                    <h3>User Testing</h3>
                    <p>
                        Validate designs with real users to ensure usability and gather valuable feedback for improvements.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaAccessibleIconIcon}</div>
                    <h3>Accessibility Compliance</h3>
                    <p>
                        We prioritize inclusivity by designing interfaces that are accessible to all users, including those with disabilities.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for UI/UX Design?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} User-centered approach for impactful designs.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in modern design tools and trends.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Collaborative process for your input at every stage.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Commitment to delivering pixel-perfect designs.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Elevate Your User Experience?</h2>
                    <p>
                        Partner with us to design stunning, user-friendly interfaces that make an impact. Contact us today to start your UI/UX design journey.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default UIUXDesignService;
