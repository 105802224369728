import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const WebDevelopmentService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="web-development-service service-sub-part">
            <header className="page-header">
                <h1>Web Development Services</h1>
                <p>We create high-performance websites tailored to your business needs.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Web Development</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Transforming Ideas into Digital Realities</h2>
                <p>
                    At DiziDesert, we specialize in designing and developing cutting-edge websites that deliver exceptional user experiences. Our web development team utilizes modern frameworks like React, Angular, and Node.js to create websites that are not only beautiful but also functional and scalable.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaLaptopCodeIcon}</div>
                    <h3>Custom Website Design</h3>
                    <p>
                        We provide bespoke website designs that cater to your specific business goals and user needs.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.SiStackbitIcon}</div>
                    <h3>Full-Stack Development</h3>
                    <p>
                        Our developers are skilled in both front-end and back-end development, ensuring a complete solution for your website.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdDevicesIcon}</div>
                    <h3>Responsive Design</h3>
                    <p>
                        Every website we build is fully responsive and optimized for all devices, ensuring a seamless experience for users across desktops, tablets, and mobile phones.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbWorldSearchIcon}</div>
                    <h3>SEO Optimization</h3>
                    <p>
                        We implement best practices for SEO to ensure your website ranks well on search engines and attracts more organic traffic.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbSettingsSearchIcon}</div>
                    <h3>Analytics and Reporting</h3>
                    <p>
                        We integrate analytics tools to track your website's performance and provide detailed reports, helping you make data-driven decisions.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiPrinterCloudFillIcon}</div>
                    <h3>Cloud Hosting Integration</h3>
                    <p>
                        We provide cloud hosting solutions for scalability, security, and performance, ensuring that your website runs smoothly at all times.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Web Development?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in the latest web technologies.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Dedicated project management for timely delivery.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Customizable solutions tailored to your needs.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Ongoing support and maintenance post-launch.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Start Your Web Development Project?</h2>
                    <p>
                        Let's work together to bring your vision to life. Contact us today and let's discuss how we can help you succeed online.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default WebDevelopmentService;
