import React, { useEffect } from "react";
import "./ServicePage.css";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon";

const ServicePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <div className="service-page">
        <header className="service-header">
          <h1>DiziDesert Services</h1>
          <p>Your gateway to premium web development and digital solutions.</p>
        </header>

        <section className="services-overview">
          <h2>Our Expertise</h2>
          <p>
            At DiziDesert, we are committed to delivering state-of-the-art digital
            services that drive success. From website development to advanced applications,
            we cater to diverse business needs with precision.
          </p>
        </section>

        <section className="service-list">
          <NavLink to="/services/web-development" className="service-item">
            <div className="service-icon">{ImportIcon.FaLaptopCodeIcon}</div>
            <h3>
              Web Development <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Transform your ideas into reality with dynamic, responsive websites.
              Our team leverages the latest frameworks like React and Angular to build
              fast, reliable solutions.
            </p>
          </NavLink>

          <NavLink to="/services/ecommerce-platforms" className="service-item">
            <div className="service-icon">{ImportIcon.FaShoppingCartIcon}</div>
            <h3>
              eCommerce Platforms <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Build a thriving online store with our tailored eCommerce solutions.
              We ensure secure transactions, seamless navigation, and scalability.
            </p>
          </NavLink>

          <NavLink to="/services/mobile-app-development" className="service-item">
            <div className="service-icon">{ImportIcon.FaMobileAltIcon}</div>
            <h3>
              Mobile App Development <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Reach your audience with interactive mobile apps for Android and iOS.
              Our apps are designed for performance and user satisfaction.
            </p>
          </NavLink>

          <NavLink to="/services/seo-digital-marketing" className="service-item">
            <div className="service-icon">{ImportIcon.FaChartLineIcon}</div>
            <h3>
              SEO & Digital Marketing <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Amplify your online presence with targeted marketing strategies.
              Our SEO and digital campaigns ensure maximum reach and impact.
            </p>
          </NavLink>

          <NavLink to="/services/ui-ux-design" className="service-item">
            <div className="service-icon">{ImportIcon.FaPaletteIcon}</div>
            <h3>
              UI/UX Design <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Elevate user experience with creative and intuitive designs. We craft
              interfaces that captivate and engage users effectively.
            </p>
          </NavLink>

          <NavLink to="/services/digital-visiting-card" className="service-item">
            <div className="service-icon">{ImportIcon.FaIdBadgeIcon}</div>
            <h3>
              Digital Visiting Card <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Make a lasting impression with personalized digital visiting cards.
              Share your contact details instantly and in style.
            </p>
          </NavLink>

          <NavLink to="/services/digital-invitation-card" className="service-item">
            <div className="service-icon">{ImportIcon.FaEnvelopeOpenTextIcon}</div>
            <h3>
              Digital Invitation Card <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Celebrate your special moments with custom digital invitations.
              Perfect for weddings, events, and parties, designed just for you.
            </p>
          </NavLink>

          <NavLink to="/services/custom-logo-design" className="service-item">
            <div className="service-icon">{ImportIcon.FaShapesIcon}</div>
            <h3>
              Custom Logo Design <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Define your brand with a unique and professional logo. Our designers
              craft logos that leave a strong visual impact.
            </p>
          </NavLink>

          <NavLink to="/services/enable-nfc-card" className="service-item">
            <div className="service-icon">{ImportIcon.LuSmartphoneNfcIcon}</div>
            <h3>
              Enable NFC Card <span className="maximize-icon">{ImportIcon.TbWindowMaximizeIcon}</span>
            </h3>
            <p>
              Simplify your interactions with NFC cards. Share your contact details,
              product information, or services instantly with just a tap.
            </p>
          </NavLink>
        </section>

        <section className="why-build-combine">
          <div className="why-choose-us">
            <h2>Why Choose Us?</h2>
            <ul className="icon-list">
              <li>
                <span className="list-icon">{ImportIcon.FaHandPointRightIcon}</span>
                Proven expertise in digital transformation.
              </li>
              <li>
                <span className="list-icon">{ImportIcon.FaHandPointRightIcon}</span>
                Client-centric approach for personalized solutions.
              </li>
              <li>
                <span className="list-icon">{ImportIcon.FaHandPointRightIcon}</span>
                Timely delivery with consistent support.
              </li>
              <li>
                <span className="list-icon">{ImportIcon.FaHandPointRightIcon}</span>
                Cost-effective services without compromising quality.
              </li>
            </ul>
          </div>

          <div className="cta-section">
            <h2>Let’s Build the Future Together</h2>
            <p>
              Collaborate with DiziDesert to unlock your business’s potential. Contact us
              today and embark on a journey to digital excellence.
            </p>
            <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServicePage;
