import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const DigitalVisitingCardService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="digital-visiting-card-service service-sub-part">
            <header className="page-header">
                <h1>Digital Visiting Card Services</h1>
                <p>Create, share, and manage your professional digital visiting card effortlessly.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Digital Visiting Card</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Network with Digital Cards</h2>
                <p>
                    DiziDesert provides innovative digital visiting card solutions that make sharing contact details easier than ever. Impress your connections with sleek, interactive, and eco-friendly digital cards.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaMobileAltIcon}</div>
                    <h3>Sustainable Solutions</h3>
                    <p>
                        Say goodbye to traditional paper cards. Our digital cards are sustainable and environmentally friendly.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdQrCodeScannerIcon}</div>
                    <h3>QR Code Customization</h3>
                    <p>
                        Share your card easily via personalized QR codes. Customize the design to reflect your brand identity.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaUserEditIcon}</div>
                    <h3>Real-Time Updates</h3>
                    <p>
                        Update your contact details anytime without reprinting. Keep your network updated effortlessly.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.GrContactInfoIcon}</div>
                    <h3>Complete Contact Info</h3>
                    <p>
                        Consolidate all your contact details, making it easier for others to reach you. Everything they need in one place.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaWhatsappIcon}</div>
                    <h3>WhatsApp Sharing</h3>
                    <p>
                        Share your card directly through WhatsApp for instant connection. Build stronger relationships quickly.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaAddressBookIcon}</div>
                    <h3>Contact Integration</h3>
                    <p>
                        Enable users to save your details directly to their phone contacts. Simplify the connection process.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaDownloadIcon}</div>
                    <h3>App Installation</h3>
                    <p>
                        Provide an option to install your digital visiting card as a PWA for offline access. Make it always available.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaShareSquareIcon}</div>
                    <h3>Web-Based Sharing</h3>
                    <p>
                        Share your card seamlessly via web-sharing features. Reach more people effortlessly and confidently.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaIconsIcon}</div>
                    <h3>Social Media Integration</h3>
                    <p>
                        Include clickable icons for all your social media profiles. Enhance your online presence.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaBriefcaseIcon}</div>
                    <h3>Business Services Display</h3>
                    <p>
                        Highlight all the services your business offers. Showcase your expertise and capabilities effectively.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaImagesIcon}</div>
                    <h3>Multimedia Showcase</h3>
                    <p>
                        Showcase your work through an integrated gallery and video section. Make a lasting impression.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaCommentDotsIcon}</div>
                    <h3>Client Testimonials</h3>
                    <p>
                        Display customer reviews and feedback directly on your card. Build trust with potential clients.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Digital Visiting Cards?</h2>
                    <ul className="reasons-list">
                        <li>{ImportIcon.FaHandPointRightIcon} Innovative and customizable designs.</li>
                        <li>{ImportIcon.FaHandPointRightIcon} Easy sharing and management options.</li>
                        <li>{ImportIcon.FaHandPointRightIcon} Comprehensive features for modern networking.</li>
                        <li>{ImportIcon.FaHandPointRightIcon} Reliable support and updates.</li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Start Your Digital Visiting Card Journey Today!</h2>
                    <p>
                        Upgrade your professional networking game with our digital visiting cards. Contact us now to get started.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default DigitalVisitingCardService;
