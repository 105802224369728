import React from "react";
import "./MainPageDetail.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import HomePage from "../HomePage/HomePage";
import AboutPage from "../AboutPage/AboutPage";
import ServicePage from "../ServicePage/ServicePage";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import WebDevelopmentService from "../ServiceSubPart/WebDevelopmentService";
import ECommercePlatformsService from "../ServiceSubPart/ECommercePlatformsService";
import MobileAppDevelopmentService from "../ServiceSubPart/MobileAppDevelopmentService";
import SEODigitalMarketingService from "../ServiceSubPart/SEODigitalMarketingService";
import UIUXDesignService from "../ServiceSubPart/UIUXDesignService";
import DigitalVisitingCardService from "../ServiceSubPart/DigitalVisitingCardService";
import DigitalInvitationCardService from "../ServiceSubPart/DigitalInvitationCardService";
import CustomLogoDesignService from "../ServiceSubPart/CustomLogoDesignService";
import EnableNFCService from "../ServiceSubPart/EnableNFCService";
import ComingSoon from "../ComingSoon/ComingSoon";

const MainPageDetail = () => {
    return (
        <>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/services" element={<ServicePage />} />
                    <Route path="/services/web-development" element={<WebDevelopmentService />} />
                    <Route path="/services/ecommerce-platforms" element={<ECommercePlatformsService />} />
                    <Route path="/services/mobile-app-development" element={<MobileAppDevelopmentService />} />
                    <Route path="/services/seo-digital-marketing" element={<SEODigitalMarketingService />} />
                    <Route path="/services/ui-ux-design" element={<UIUXDesignService />} />
                    <Route path="/services/digital-visiting-card" element={<DigitalVisitingCardService />} />
                    <Route path="/services/digital-invitation-card" element={<DigitalInvitationCardService />} />
                    <Route path="/services/custom-logo-design" element={<CustomLogoDesignService />} />
                    <Route path="/services/enable-nfc-card" element={<EnableNFCService/>} />
                    <Route path="/profile" element={<ComingSoon pageName="Profile Page"/>} />
                    <Route path="/clients" element={<ComingSoon pageName="Clients Page"/>} />
                    <Route path="/works" element={<ComingSoon pageName="Works Page"/>} />
                    <Route path="/contact" element={<ComingSoon pageName="Contact Page"/>} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Router>
        </>
    )
}

export default MainPageDetail;
