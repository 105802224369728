import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon";
import { ImportImage } from "../../pages/ImportImage";
import { dataSetForBasicData } from "../../pages/ImportAllDetail";
import "./Navbar.css";
import InstallAppButton from "../InstallAppButton/InstallAppButton";

const Navbar = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownVisible(!dropdownVisible);
    };

    const closeDropdown = () => {
        setDropdownVisible(false);
    };

    const renderDropdownMenu = () => (
        <ul className="dropdown-menu">
            <li style={{justifyContent:"center"}}>
                <img src={ImportImage.DiziDesertLogo} alt="DiziDesert Logo" height="30px" width="auto"/>
            </li>
            <li className="redirect_navbar_menu">
                <NavLink to="/signin" onClick={closeDropdown}>{ImportIcon.RiUserReceivedFillIcon} Sign In</NavLink>
            </li>
            <li className="redirect_navbar_menu">
                <NavLink to="/signup" onClick={closeDropdown}>{ImportIcon.RiUserAddFillIcon} Sign Up</NavLink>
            </li>
            <li className="redirect_navbar_menu">
                <NavLink to="/blog" onClick={closeDropdown}>{ImportIcon.FaBloggerBIcon} Blog</NavLink>
            </li>
            <hr />
            <li className="add_contact_install">
                <div className="redirect_navbar_menu add_to_contact">
                    <a
                        href={ImportImage.AddToContact}
                        download
                    >
                        {ImportIcon.FaAddressBookIcon} Add To Contact
                    </a>
                </div>
                <div className="redirect_navbar_menu install_button">
                    <InstallAppButton />
                </div>
            </li>
            <hr />
            <li>
                <ul className="all-social-icon">
                    <li>
                        <a href={dataSetForBasicData.FollowLinkInstagram} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaInstagramIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={dataSetForBasicData.FollowLinkFacebook} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaFacebookFIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={dataSetForBasicData.FollowLinkLinkedin} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaLinkedinInIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={dataSetForBasicData.FollowLinkYouTube} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaYoutubeIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={dataSetForBasicData.WebSiteLink} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaGlobeAsiaIcon}</i>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <ul className="all-social-icon">
                    <li>
                        <a href={`https://api.whatsapp.com/send?phone=91${dataSetForBasicData.WhatsAppNumber}`} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaWhatsappIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={`tel:+91${dataSetForBasicData.CallingNumber}`} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaPhoneAltIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={`mailto:${dataSetForBasicData.EmailAddress}`} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaEnvelopeIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={`sms:+91${dataSetForBasicData.CallingNumber}`} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaSmsIcon}</i>
                        </a>
                    </li>
                    <li>
                        <a href={dataSetForBasicData.DirectionLinkRegisterOffice} target="_blank" rel="noreferrer">
                            <i>{ImportIcon.FaLocationDotIcon}</i>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    );

    return (
        <>
            {/* Small Device Navbar */}
            <div className="small-device-navbar">
                <div className="small-navbar navbar-logo">
                    <NavLink to="/">
                        <img src={ImportImage.DiziDesertLogo} alt="DiziDesert Logo" height="auto" width="auto"/>
                    </NavLink>
                </div>
                <div className="small-navbar navbar-links more-option">
                    <div className="dropdown-container" ref={dropdownRef}>
                        <button className="dropdown-toggle" onClick={toggleDropdown}>
                            {dropdownVisible ? (
                                <>
                                    {ImportIcon.FaTimesIcon}
                                    <span>Close</span>
                                </>
                            ) : (
                                <>
                                    {ImportIcon.CgMenuGridRIcon}
                                    <span>More</span>
                                </>
                            )}
                        </button>
                        {dropdownVisible && renderDropdownMenu()}
                    </div>
                </div>
            </div>

            {/* Desktop Navbar */}
            <nav className="navbar">
                <div className="navbar-logo">
                    <NavLink to="/">
                        <img src={ImportImage.DiziDesertLogo} alt="DiziDesert Logo" height="auto" width="auto"/>
                    </NavLink>
                </div>
                <ul className="navbar-links">
                    <li>
                        <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Home">
                            {ImportIcon.FaHomeIcon}
                            <span>Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" className={({ isActive }) => (isActive ? "active" : "")} aria-label="About us">
                            {ImportIcon.FaInfoCircleIcon}
                            <span>About</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/services" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Our Services">
                            {ImportIcon.FaServicestackIcon}
                            <span>Services</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/works" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Our Works">
                            {ImportIcon.FaBriefcaseIcon}
                            <span>Works</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/clients" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Our Clients">
                            {ImportIcon.FaUsersIcon}
                            <span>Clients</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Contact Us">
                            {ImportIcon.FaEnvelopeIcon}
                            <span>Contact</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile" className={({ isActive }) => (isActive ? "active" : "")} aria-label="Profile Detail">
                            {ImportIcon.FaUserCircleIcon}
                            <span>Profile</span>
                        </NavLink>
                    </li>
                </ul>
                <div className="navbar-links more-option">
                    <div className="dropdown-container" ref={dropdownRef}>
                        <button className="dropdown-toggle" onClick={toggleDropdown}>
                            {dropdownVisible ? (
                                <>
                                    {ImportIcon.FaTimesIcon}
                                    <span style={{fontSize:"14.3px"}}>Close</span>
                                </>
                            ) : (
                                <>
                                    {ImportIcon.CgMenuGridRIcon}
                                    <span>More</span>
                                </>
                            )}
                        </button>
                        {dropdownVisible && renderDropdownMenu()}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
