import React, { useContext } from 'react';
import './InstallAppButtonStyle.css';
import { ImportIcon } from '../../pages/ImportIcon';
import { PWAContext } from './PWAContext';

const InstallAppButton = () => {
  const { deferredPrompt, isInstallable, isInstalled, setDeferredPrompt } = useContext(PWAContext);

  const handleInstallApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          alert('App Installed Successfully');
        } else {
          console.log('User dismissed the install prompt');
          alert('App installation was cancelled');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleUninstallApp = () => {
    alert('To uninstall the app, long press on the app icon and select "Remove" or "Uninstall".');
  };

  const handleNotInstallable = () => {
    alert('Install App not supported on your device.');
  };

  return (
    <div className="install-app-button">
      {isInstalled ? (
        <button onClick={handleUninstallApp} className="btn button-color">
          {ImportIcon.FaTrashIcon} Uninstall App
        </button>
      ) : isInstallable ? (
        <button onClick={handleInstallApp} className="btn button-color install-highlight">
          {ImportIcon.FaDownloadIcon} Install App
        </button>
      ) : (
        <button onClick={handleNotInstallable} className="btn button-color">
          {ImportIcon.TbDownloadOffIcon} Not Installable
        </button>
      )}
    </div>
  );
};

export default InstallAppButton;
