import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css'; // Optional: Create a CSS file for styling
import { ImportIcon } from '../../pages/ImportIcon';

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <div className="error-page-container">
            <div className="error-page-content">
                <h1 className="error-title">Oops!</h1>
                <p className="error-message">The page you're looking for doesn't exist.</p>
                <p className="error-description">
                    It might have been moved or deleted, or you may have mistyped the URL.
                </p>
                <div className="loader">
                    <span>{ImportIcon.BiErrorAltIcon}</span>
                    <span>{ImportIcon.MdNearbyErrorIcon}</span>
                    <span>{ImportIcon.MdWifiTetheringErrorIcon}</span>
                    <span>{ImportIcon.TbError404Icon}</span>
                    <span>{ImportIcon.RiEmotionSadLineIcon}</span>
                </div>
                <button onClick={() => navigate(-1)} className="error-home-button">
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default ErrorPage;
