import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const ECommercePlatformsService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ecommerce-platforms-service service-sub-part">
            <header className="page-header">
                <h1>eCommerce Platforms</h1>
                <p>Empower your business with robust and scalable eCommerce solutions.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">eCommerce Platforms</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Building Digital Stores for the Future</h2>
                <p>
                    At DiziDesert, we specialize in creating state-of-the-art eCommerce platforms that drive sales, enhance user experiences, and simplify management. Our team delivers tailored solutions powered by platforms like Shopify, WooCommerce, and Magento.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.BiSolidStoreIcon}</div>
                    <h3>Custom Online Stores</h3>
                    <p>
                        Develop unique and visually appealing online stores that reflect your brand identity and maximize customer engagement.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiSecurePaymentFillIcon}</div>
                    <h3>Seamless Integrations</h3>
                    <p>
                        Integrate with payment gateways, CRMs, and shipping providers for a seamless shopping experience.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdSecurityIcon}</div>
                    <h3>Security & Compliance</h3>
                    <p>
                        Ensure secure transactions with industry-standard encryption and compliance with global regulations.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbDeviceAnalyticsIcon}</div>
                    <h3>Analytics & Insights</h3>
                    <p>
                        Leverage analytics tools to track sales performance and customer behavior for data-driven decisions.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbSettingsAutomationIcon}</div>
                    <h3>Automation Features</h3>
                    <p>
                        Automate inventory management, order processing, and marketing tasks to streamline operations.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdMobileFriendlyIcon}</div>
                    <h3>Mobile-Friendly Design</h3>
                    <p>
                        Build responsive and mobile-optimized eCommerce platforms to reach customers on any device.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for eCommerce Solutions?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in popular eCommerce platforms like Shopify and Magento.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Scalable solutions for businesses of all sizes.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Focus on user experience and conversion optimization.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Comprehensive support and maintenance post-launch.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Launch Your Online Store?</h2>
                    <p>
                        Take your business to the next level with a professional eCommerce platform. Contact us today to get started!
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default ECommercePlatformsService;
