import React, { createContext, useEffect, useState } from 'react';

export const PWAContext = createContext();

const PWAProvider = ({ children }) => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        // Check if the app is installed
        const checkIfInstalled = () => {
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
            setIsInstalled(isStandalone);
        };

        checkIfInstalled();

        // Listen for `beforeinstallprompt` event
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
            setIsInstallable(true);
        };

        // Listen for the app installation
        const handleAppInstalled = () => {
            setIsInstalled(true);
            setDeferredPrompt(null);
            setIsInstallable(false);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    return (
        <PWAContext.Provider value={{ deferredPrompt, isInstallable, isInstalled, setDeferredPrompt }}>
            {children}
        </PWAContext.Provider>
    );
};

export default PWAProvider;
