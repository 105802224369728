import React, { useEffect } from "react";
import "./AboutPage.css";
import { ImportImage } from "../../pages/ImportImage";

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="aboutpage_section">
                <div className="about_hero">
                    <div className="about_content">
                        <div className="main_about">
                            <img className="about_image" src={ImportImage.AboutPhoto1} alt="about_photo_1" />
                            <div className="about_text">
                                <div className="about_main_heading">
                                    We Are DiziDesert
                                </div>
                                <div className="about_text_paragraph">
                                    We are daring, innovative and creative. We believe in standing apart from the crowd. We connect brands to customers by leveraging paid platforms. Earning the attention it derserves is the prime purpose of creativity. Creativity gets the attention it deserves only through the media. The future of marketing lies in the dizital platform. If you want your brand to be accessible across a plethora of advanced channels, then going dizital is the only way. This is where we at DiziDesert come in.
                                </div>
                            </div>
                        </div>
                        <div className="main_about about_vision">
                            <div className="about_text">
                                <div className="about_main_heading">
                                    Our Vision
                                </div>
                                <div className="about_text_paragraph">
                                    At DiziDesert, we aim to redefine the digital world by delivering innovative, scalable, and user-focused web solutions. Our goal is to empower businesses to unlock the full potential of digital platforms and build seamless connections with their audiences. We strive to go beyond limits, combining creativity, technology, and excellence to turn ideas into impactful realities. Every project we undertake is a step forward in shaping the future of digital innovation.
                                </div>
                            </div>
                            <img className="about_image" src={ImportImage.AboutPhoto2} alt="about_photo_1" />
                        </div>
                        <div className="about_success_card">
                            <div className="about_main_heading">
                                Foundations of Our Success
                            </div>
                            <div className="about_card_container">
                                <div className="about_card">
                                    <img src={ImportImage.AboutInnovationPhoto} alt="about_innovation_image" className="about_card_image" />
                                    <h3 className="about_card_title">Innovation</h3>
                                    <p className="about_card_description"> We push the boundaries of creativity and technology to craft unique, cutting-edge web solutions that exceed client expectations.</p>
                                </div>
                                <div className="about_card">
                                    <img src={ImportImage.AboutIntegrityPhoto} alt="about_integrity_image" className="about_card_image" />
                                    <h3 className="about_card_title">Integrity</h3>
                                    <p className="about_card_description">Transparency, honesty, and ethical practices are the cornerstones of our business, ensuring reliability and long-lasting trust.</p>
                                </div>
                                <div className="about_card">
                                    <img src={ImportImage.AboutQualityPhoto} alt="about_quality_image" className="about_card_image" />
                                    <h3 className="about_card_title">Quality Excellence</h3>
                                    <p className="about_card_description">DiziDesert are deliver impeccable quality in every project, ensuring our work meets and exceeds industry benchmarks.</p>
                                </div>
                                <div className="about_card">
                                    <img src={ImportImage.AboutTeamWorkPhoto} alt="about_client_focus_image" className="about_card_image" />
                                    <h3 className="about_card_title">Team Work</h3>
                                    <p className="about_card_description">Collaboration and collective expertise drive our success. We believe in growing together as a cohesive and motivated team.</p>
                                </div>
                                <div className="about_card">
                                    <img src={ImportImage.AboutClientFocusPhoto} alt="about_client_focus_image" className="about_card_image" />
                                    <h3 className="about_card_title">Client Focus</h3>
                                    <p className="about_card_description">We prioritize understanding and delivering personalized solutions tailored to our clients’ success, fostering lasting relationships.</p>
                                </div>
                                <div className="about_card">
                                    <img src={ImportImage.AboutSustainabilityPhoto} alt="about_client_focus_image" className="about_card_image" />
                                    <h3 className="about_card_title">Sustainability</h3>
                                    <p className="about_card_description">We incorporate sustainable and responsible practices in our operations, ensuring long-term value for clients and the environment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="about_success_card">
                            <div className="about_main_heading">
                                Our Acheivement
                            </div>
                            <div className="about_card_container">
                                <div className="about_card_acheivement">
                                    <h3 className="about_card_achivement_title">Award Received 🏆</h3>
                                    <p className="about_card_achivement_description"> 15 +</p>
                                </div>
                                <div className="about_card_acheivement">
                                    <h3 className="about_card_achivement_title">Project Complete 📁</h3>
                                    <p className="about_card_achivement_description">195 +</p>
                                </div>
                                <div className="about_card_acheivement">
                                    <h3 className="about_card_achivement_title">Happy Customer 😊</h3>
                                    <p className="about_card_achivement_description">150 +</p>
                                </div>
                                <div className="about_card_acheivement">
                                    <h3 className="about_card_achivement_title">Business Started 🗓️</h3>
                                    <p className="about_card_achivement_description">Oct, 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutPage;