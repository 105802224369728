import { FaHome, FaInfoCircle, FaServicestack, FaBloggerB, FaUsers, FaBriefcase, FaEnvelope, FaUserCircle,
    FaInstagram, FaFacebookF, FaLinkedinIn, FaWhatsapp, FaGlobeAsia, FaYoutube, FaPhoneAlt, FaSms, FaTimes, 
    FaLaptopCode, FaShoppingCart, FaMobileAlt, FaChartLine, FaPalette, FaIdBadge, FaEnvelopeOpenText, FaShapes,
    FaHandPointRight, FaRocket, FaSearchDollar, FaMailBulk, FaRegLightbulb, FaPencilRuler, FaAccessibleIcon,
    FaUserEdit, FaShareSquare, FaIcons, FaImages, FaCommentDots, FaShareAlt, FaMapMarkedAlt, FaSketch, FaCogs, 
    FaCreditCard, FaTag
} from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";
import { FaLocationDot, FaDownload, FaTrash, FaAddressBook } from "react-icons/fa6";
import { RiUserAddFill, RiUserReceivedFill, RiPrinterCloudFill, RiSecurePaymentFill, RiMusicAiFill, RiVideoOnAiFill,
    RiBrushAiFill, RiCameraLensAiFill, RiWirelessChargingFill, RiUserSettingsFill, RiEmotionSadLine
 } from "react-icons/ri";
import { TbDownloadOff, TbWindowMaximize, TbWorldSearch, TbSettingsSearch, TbSquareRoundedChevronsRightFilled,
    TbDeviceAnalytics, TbSettingsAutomation, TbDeviceMobileCode, TbCloudDataConnection, TbReportSearch,
    TbBrandReactNative, TbFileTypeZip, TbClockEdit, TbClockCode, TbClockCog, TbClockDown, TbClockCheck, TbError404
} from "react-icons/tb";
import { LuSmartphoneNfc } from "react-icons/lu";
import { SiStackbit, SiAuthelia } from "react-icons/si";
import { BiSolidStore, BiErrorAlt } from "react-icons/bi";
import { MdSecurity, MdDevices, MdMobileFriendly, MdOnDeviceTraining, MdOutlineCampaign, MdQrCodeScanner, MdHighQuality,
    MdOutlinePhonelinkSetup, MdCloudSync, MdWifiTetheringError, MdNearbyError
 } from "react-icons/md";
import { AiFillExperiment } from "react-icons/ai";
import { PiDevicesBold } from "react-icons/pi";
import { GiWoodFrame } from "react-icons/gi";
import { VscFeedback } from "react-icons/vsc";
import { GrContactInfo } from "react-icons/gr";
import { RxLapTimer } from "react-icons/rx";
import { IoCalendarNumber } from "react-icons/io5";

export const ImportIcon = {
    CgMenuGridRIcon: <CgMenuGridR />,
    FaHomeIcon: <FaHome />,
    FaInfoCircleIcon: <FaInfoCircle />,
    FaServicestackIcon: <FaServicestack />,
    FaBloggerBIcon: <FaBloggerB />,
    FaUsersIcon: <FaUsers />,
    FaBriefcaseIcon: <FaBriefcase />,
    FaEnvelopeIcon: <FaEnvelope />,
    FaUserCircleIcon: <FaUserCircle />,
    FaInstagramIcon: <FaInstagram />,
    FaFacebookFIcon: <FaFacebookF />,
    FaLinkedinInIcon: <FaLinkedinIn />,
    FaWhatsappIcon: <FaWhatsapp />,
    FaGlobeAsiaIcon: <FaGlobeAsia />,
    FaYoutubeIcon: <FaYoutube />,
    FaPhoneAltIcon: <FaPhoneAlt />,
    FaLocationDotIcon: <FaLocationDot />,
    FaSmsIcon: <FaSms />,
    FaTimesIcon: <FaTimes />,
    FaAddressBookIcon: <FaAddressBook />,
    FaDownloadIcon: <FaDownload />,
    TbDownloadOffIcon: <TbDownloadOff />,
    FaTrashIcon: <FaTrash />,
    RiUserAddFillIcon: <RiUserAddFill />,
    RiUserReceivedFillIcon: <RiUserReceivedFill />,
    FaLaptopCodeIcon: <FaLaptopCode />, 
    FaShoppingCartIcon: <FaShoppingCart />, 
    FaMobileAltIcon: <FaMobileAlt />, 
    FaChartLineIcon: <FaChartLine />, 
    FaPaletteIcon: <FaPalette />, 
    FaIdBadgeIcon: <FaIdBadge />, 
    FaEnvelopeOpenTextIcon: <FaEnvelopeOpenText />, 
    FaShapesIcon: <FaShapes />,
    LuSmartphoneNfcIcon: <LuSmartphoneNfc />,
    FaHandPointRightIcon: <FaHandPointRight />,
    TbWindowMaximizeIcon: <TbWindowMaximize />,
    SiStackbitIcon: <SiStackbit />,
    TbWorldSearchIcon: <TbWorldSearch />,
    TbSettingsSearchIcon: <TbSettingsSearch />,
    RiPrinterCloudFillIcon: <RiPrinterCloudFill />,
    TbSquareRoundedChevronsRightFilledIcon: <TbSquareRoundedChevronsRightFilled />,
    BiSolidStoreIcon: <BiSolidStore />,
    RiSecurePaymentFillIcon: <RiSecurePaymentFill />,
    MdSecurityIcon: <MdSecurity />,
    TbDeviceAnalyticsIcon: <TbDeviceAnalytics />,
    TbSettingsAutomationIcon: <TbSettingsAutomation />,
    TbDeviceMobileCodeIcon: <TbDeviceMobileCode />,
    TbCloudDataConnectionIcon: <TbCloudDataConnection />,
    SiAutheliaIcon: <SiAuthelia />,
    FaRocketIcon: <FaRocket />,
    MdOnDeviceTrainingIcon: <MdOnDeviceTraining />,
    MdDevicesIcon: <MdDevices />,
    MdMobileFriendlyIcon: <MdMobileFriendly />,
    TbBrandReactNativeIcon: <TbBrandReactNative />,
    FaSearchDollarIcon: <FaSearchDollar />,
    MdOutlineCampaignIcon: <MdOutlineCampaign />,
    FaMailBulkIcon: <FaMailBulk />,
    TbReportSearchIcon: <TbReportSearch />,
    FaRegLightbulbIcon: <FaRegLightbulb />,
    FaPencilRulerIcon: <FaPencilRuler />,
    AiFillExperimentIcon: <AiFillExperiment />,
    PiDevicesBoldIcon: <PiDevicesBold />,
    GiWoodFrameIcon: <GiWoodFrame />,
    VscFeedbackIcon: <VscFeedback />,
    FaAccessibleIconIcon: <FaAccessibleIcon />,
    MdQrCodeScannerIcon: <MdQrCodeScanner />,
    FaUserEditIcon: <FaUserEdit />,
    GrContactInfoIcon: <GrContactInfo />,
    FaShareSquareIcon: <FaShareSquare />,
    FaIconsIcon: <FaIcons />,
    FaImagesIcon: <FaImages />,
    FaCommentDotsIcon: <FaCommentDots />,
    RxLapTimerIcon: <RxLapTimer />,
    FaShareAltIcon: <FaShareAlt />,
    RiMusicAiFillIcon: <RiMusicAiFill />,
    RiVideoOnAiFillIcon: <RiVideoOnAiFill />,
    FaMapMarkedAltIcon: <FaMapMarkedAlt />,
    IoCalendarNumberIcon: <IoCalendarNumber />,
    RiBrushAiFillIcon: <RiBrushAiFill />,
    FaSketchIcon: <FaSketch />,
    RiCameraLensAiFillIcon: <RiCameraLensAiFill />,
    MdHighQualityIcon: <MdHighQuality />,
    TbFileTypeZipIcon: <TbFileTypeZip />,
    FaCogsIcon: <FaCogs />,
    FaCreditCardIcon: <FaCreditCard />,
    FaTagIcon: <FaTag />,
    RiWirelessChargingFillIcon: <RiWirelessChargingFill />,
    MdOutlinePhonelinkSetupIcon: <MdOutlinePhonelinkSetup />,
    RiUserSettingsFillIcon: <RiUserSettingsFill />,
    MdCloudSyncIcon: <MdCloudSync />,
    TbClockEditIcon: <TbClockEdit />,
    TbClockCodeIcon: <TbClockCode />,
    TbClockCogIcon: <TbClockCog />,
    TbClockDownIcon: <TbClockDown />,
    TbClockCheckIcon: <TbClockCheck />,
    RiEmotionSadLineIcon: <RiEmotionSadLine />,
    TbError404Icon: <TbError404 />,
    MdWifiTetheringErrorIcon: <MdWifiTetheringError />,
    BiErrorAltIcon: <BiErrorAlt />,
    MdNearbyErrorIcon: <MdNearbyError />
};