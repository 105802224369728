import './App.css';
import MainPageDetail from './components/MainPageDetail/MainPageDetail';

function App() {
  return (
    <>
      <MainPageDetail />
    </>
  );
}

export default App;
