import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const SEODigitalMarketingService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="seo-digital-marketing-service service-sub-part">
            <header className="page-header">
                <h1>SEO & Digital Marketing Services</h1>
                <p>Enhancing your online presence to drive traffic and grow your business.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">SEO & Digital Marketing</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Boost Your Online Visibility</h2>
                <p>
                    At DiziDesert, we offer a comprehensive suite of SEO and digital marketing services to help you stand out in the competitive digital landscape. Our strategies are designed to drive traffic, improve conversions, and maximize ROI.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaSearchDollarIcon}</div>
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>
                        Improve your website's ranking on search engines with our proven SEO techniques and keyword optimization strategies.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdOutlineCampaignIcon}</div>
                    <h3>Pay-Per-Click Advertising (PPC)</h3>
                    <p>
                        Get instant visibility and attract targeted traffic with our expertly managed PPC campaigns.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaUsersIcon}</div>
                    <h3>Social Media Marketing</h3>
                    <p>
                        Engage with your audience and build your brand on platforms like Facebook, Instagram, LinkedIn, and Twitter.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaMailBulkIcon}</div>
                    <h3>Email Marketing</h3>
                    <p>
                        Reach out to your customers with personalized email campaigns that drive engagement and conversions.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbReportSearchIcon}</div>
                    <h3>Analytics and Reporting</h3>
                    <p>
                        Measure your campaign performance with detailed analytics and insights to refine your strategies.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaRegLightbulbIcon}</div>
                    <h3>Content Marketing</h3>
                    <p>
                        Create high-quality, engaging content that resonates with your audience and boosts your online presence.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Digital Marketing?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Experienced marketers with a track record of success.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Data-driven strategies for maximum ROI.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Transparent communication and regular updates.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} End-to-end marketing solutions tailored to your business.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Take Your Digital Marketing to the Next Level</h2>
                    <p>
                        Partner with us to create a powerful online presence and achieve your business goals. Contact us today to get started.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default SEODigitalMarketingService;
