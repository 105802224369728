import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const DigitalInvitationCardService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="digital-invitation-card-service service-sub-part">
            <header className="page-header">
                <h1>Digital Invitation Card Services</h1>
                <p>We create memorable digital invitation cards for your special occasions.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Digital Invitation Cards</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Creating Personalized Invitations</h2>
                <p>
                    At DiziDesert, we design unique and personalized digital invitation cards for weddings, birthdays, and other special events. Our designs are stylish, interactive, and crafted to make your occasion unforgettable.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaPencilRulerIcon}</div>
                    <h3>Custom Designs All Event</h3>
                    <p>
                        Tailor your digital invitation to match the theme and style of your event. Whether it's a wedding, birthday, or any special occasion, we provide personalized designs.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdMobileFriendlyIcon}</div>
                    <h3>Mobile-Optimized</h3>
                    <p>
                        Our invitations are designed for seamless viewing on mobile devices, making it easy for your guests to access and respond on the go.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RxLapTimerIcon}</div>
                    <h3>Instant Delivery</h3>
                    <p>
                        Get your digital invitation delivered quickly, without compromising on quality. We guarantee timely delivery for every event.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaShareAltIcon}</div>
                    <h3>Easy Sharing</h3>
                    <p>
                        Share your digital invitations effortlessly through social media, email, or messaging apps, making it simple for your guests to receive and share.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiMusicAiFillIcon} {ImportIcon.RiVideoOnAiFillIcon}</div>
                    <h3>Audio/Video Your Style</h3>
                    <p>
                        Enhance your invitation with a personal touch by adding a custom audio or video message to welcome your guests.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaAddressBookIcon}</div>
                    <h3>Inviter Contact Info</h3>
                    <p>
                        Easily include the inviter's contact details, such as phone number or email address, making it simple for guests to reach out..
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaMapMarkedAltIcon}</div>
                    <h3>Venue Location</h3>
                    <p>
                        Provide guests with the exact venue location using interactive maps, ensuring they can easily find the event venue.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.IoCalendarNumberIcon}</div>
                    <h3>Venue Date & Time</h3>
                    <p>
                        Clearly display the date and time of the event, so your guests are informed and can mark their calendars accordingly.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Digital Invitations?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Fully customized designs for every occasion.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Quick and efficient service with on-time delivery.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Seamless mobile optimization for easy access.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Simplified RSVP management for better event coordination.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Design Your Invitation?</h2>
                    <p>
                        Get in touch with us today to start creating your custom digital invitation card and make your event unforgettable.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default DigitalInvitationCardService;
