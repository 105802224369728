import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const EnableNFCService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="enable-nfc-service service-sub-part">
            <header className="page-header">
                <h1>Enable NFC Card Services</h1>
                <p>Enhance your customer experience with NFC-enabled cards for easy access and seamless interactions.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Enable NFC Cards</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Smart Business NFC Technology</h2>
                <p>
                    DiziDesert offers NFC card services that provide fast, secure, and contactless interactions for your business. Whether for access control, payments, or identification, our NFC-enabled cards make everyday tasks more efficient and reliable.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaCreditCardIcon}</div>
                    <h3>Seamless Payments</h3>
                    <p>
                        Enable fast and secure payment options through NFC technology, making transactions easier for customers and businesses.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdSecurityIcon}</div>
                    <h3>Enhanced Security</h3>
                    <p>
                        NFC cards offer encrypted and secure transactions, reducing the risk of fraud and unauthorized access.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiWirelessChargingFillIcon}</div>
                    <h3>Contactless Access</h3>
                    <p>
                        Easily manage access control with NFC cards, allowing authorized personnel to gain access through a simple tap.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdOutlinePhonelinkSetupIcon}</div>
                    <h3>Quick Setup & Integration</h3>
                    <p>
                        NFC cards can be quickly integrated with your existing systems, allowing for a fast implementation process.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.RiUserSettingsFillIcon}</div>
                    <h3>Customizable Solutions</h3>
                    <p>
                        We offer customized NFC solutions tailored to your business needs, whether for membership cards, loyalty programs, or access management.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdCloudSyncIcon}</div>
                    <h3>Easy Data Synchronization</h3>
                    <p>
                        Synchronize your NFC cards with your existing databases and systems for smooth and continuous operations.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for NFC Card Solutions?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in implementing NFC technology for various industries.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Secure and reliable NFC card solutions that enhance user experience.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Easy integration with your current systems for a seamless transition.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Ongoing support and updates for NFC card functionalities.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Enable NFC for Your Business?</h2>
                    <p>
                        Get in touch with us to explore how NFC-enabled cards can revolutionize your business operations and enhance your customer interactions.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default EnableNFCService;
