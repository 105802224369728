import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./HomePage.css";
import { ImportIcon } from "../../pages/ImportIcon";
import { dataSetForBasicData } from "../../pages/ImportAllDetail";
import { ImportImage } from "../../pages/ImportImage";

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="navbar-padding-section homepage">
                <div className="hero">
                    <img src={ImportImage.HomePageBackgroundPhoto} alt="homebg_image" className="home-page-background" height="auto" width="auto" />
                    <div className="hero-content">
                        <div className="welcome-text">WELCOME TO DIZIDESERT</div>
                        <div className="powerfull-text">
                            Looking for <br /> Most Powerfull <br /> Marketing Services
                        </div>
                        <div className="service-contact">
                            <div className="both_button service-button">
                                <NavLink to="/services" className="btn-primary">
                                    Explore Services
                                </NavLink>
                            </div>
                            <div className="both_button contact-button">
                                <NavLink to="/contact" className="btn-primary">
                                    Contact Us
                                </NavLink>
                            </div>
                        </div>
                        <div className="home-social-icon">
                            <ul className="all-social-icon">
                                <li>
                                    <a href={dataSetForBasicData.FollowLinkInstagram} target="_blank" rel="noreferrer" aria-label="Follow On Instagram">
                                        <i>{ImportIcon.FaInstagramIcon}</i>
                                        <p>Instagram</p>
                                    </a>
                                </li>
                                <li>
                                    <a href={dataSetForBasicData.FollowLinkFacebook} target="_blank" rel="noreferrer" aria-label="Connect With Facebook">
                                        <i>{ImportIcon.FaFacebookFIcon}</i>
                                        <p>Facebook</p>
                                    </a>
                                </li>
                                <li>
                                    <a href={dataSetForBasicData.FollowLinkLinkedin} target="_blank" rel="noreferrer" aria-label="Connect On Linkedin">
                                        <i>{ImportIcon.FaLinkedinInIcon}</i>
                                        <p>Linkedin</p>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://api.whatsapp.com/send?phone=91${dataSetForBasicData.WhatsAppNumber}`} target="_blank" rel="noreferrer" aria-label="Message On Whatsapp">
                                        <i>{ImportIcon.FaWhatsappIcon}</i>
                                        <p>Whatsapp</p>
                                    </a>
                                </li>
                                <li>
                                    <a href={dataSetForBasicData.FollowLinkYouTube} target="_blank" rel="noreferrer" aria-label="Subscribe Our YouTube Channel">
                                        <i>{ImportIcon.FaYoutubeIcon}</i>
                                        <p>YouTube</p>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default HomePage;
