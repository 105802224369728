import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ImportIcon } from "../../pages/ImportIcon"; // Import from the external icon file
import "./ServiceSubPart.css";

const MobileAppDevelopmentService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="mobile-app-development-service service-sub-part">
            <header className="page-header">
                <h1>Mobile App Design Services</h1>
                <p>We create innovative and user-friendly mobile applications tailored to your business needs.</p>
                <nav className="breadcrumbs">
                    <NavLink to="/" className="breadcrumb-link">Home</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <NavLink to="/services" className="breadcrumb-link">Services</NavLink>
                    <span className="breadcrumb-separator">{ImportIcon.TbSquareRoundedChevronsRightFilledIcon}</span>
                    <span className="breadcrumb-current">Mobile App Design</span>
                </nav>
            </header>

            <section className="service-overview">
                <h2>Mobile Innovation for Growth</h2>
                <p>
                    At DiziDesert, we specialize in building mobile apps that deliver exceptional user experiences, drive engagement, and enhance business efficiency. From concept to launch, we offer comprehensive mobile app development services for iOS, Android, and cross-platform solutions.
                </p>
            </section>

            <section className="key-features">
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbDeviceMobileCodeIcon}</div>
                    <h3>Custom App Design</h3>
                    <p>
                        Crafting visually stunning and intuitive designs that captivate users and align with your brand identity.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbBrandReactNativeIcon}</div>
                    <h3>Native & Cross-Platform</h3>
                    <p>
                        Offering both native iOS/Android apps and cross-platform solutions using frameworks like Flutter and React Native.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.TbCloudDataConnectionIcon}</div>
                    <h3>Cloud Integration</h3>
                    <p>
                        Integrating cloud services to enable real-time data synchronization and scalability for your mobile app.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.SiAutheliaIcon}</div>
                    <h3>High-Level Security</h3>
                    <p>
                        Implementing robust security measures to protect your app and its users from potential threats.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.FaRocketIcon}</div>
                    <h3>Performance Optimization</h3>
                    <p>
                        Ensuring lightning-fast performance and seamless user experience across all devices.
                    </p>
                </div>
                <div className="feature-item">
                    <div className="service-icon">{ImportIcon.MdOnDeviceTrainingIcon}</div>
                    <h3>Analytics and Insights</h3>
                    <p>
                        Integrating analytics tools to track user behavior, measure performance, and optimize your app.
                    </p>
                </div>
            </section>

            <section className="choose-get-combine">
                <div className="why-choose-us">
                    <h2>Why Choose DiziDesert for Mobile App Design?</h2>
                    <ul className="reasons-list">
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Expertise in the latest mobile app development frameworks.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} End-to-end services from ideation to deployment.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Focus on delivering exceptional user experiences.
                        </li>
                        <li>
                            {ImportIcon.FaHandPointRightIcon} Reliable post-launch support and maintenance.
                        </li>
                    </ul>
                </div>

                <div className="cta-section">
                    <h2>Ready to Build Your Mobile App?</h2>
                    <p>
                        Let’s bring your app idea to life. Contact us today and discover how we can create a cutting-edge mobile solution for your business.
                    </p>
                    <NavLink to="/contact" className="cta-button">Get in Touch</NavLink>
                </div>
            </section>
        </div>
    );
};

export default MobileAppDevelopmentService;
