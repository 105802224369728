import mainvcf from "../assets/accessories/main.vcf";
import DiziDesertLogoRectangular from "../assets/images/dizidesert_black.png"; 
import HomePageBackgroundImage from "../assets/images/homepage_bg.jpg";
import AboutImage1 from "../assets/images/about_image_1.jpg";
import AboutImage2 from "../assets/images/about_image_2.jpg";
import AboutInnovationImage from "../assets/images/about_innovation.jpg";
import AboutIntegrityImage from "../assets/images/about_integrity.jpg";
import AboutQualityImage from "../assets/images/about_quality.jpg";
import AboutTeamWorkImage from "../assets/images/about_team_work.jpg";
import AboutClientFocusImage from "../assets/images/about_client_focus.jpg";
import AboutSustainabilityImage from "../assets/images/about_sustainability.jpg";



export const ImportImage = {
    AddToContact:mainvcf,
    DiziDesertLogo: DiziDesertLogoRectangular,
    HomePageBackgroundPhoto: HomePageBackgroundImage,
    AboutPhoto1: AboutImage1,
    AboutPhoto2: AboutImage2,
    AboutInnovationPhoto: AboutInnovationImage,
    AboutIntegrityPhoto: AboutIntegrityImage,
    AboutQualityPhoto: AboutQualityImage,
    AboutClientFocusPhoto: AboutClientFocusImage,
    AboutTeamWorkPhoto: AboutTeamWorkImage,
    AboutSustainabilityPhoto: AboutSustainabilityImage,
};